function Page404() {
  return (
    <div>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>404 Error</title>
      <link href="css/bootstrap.min.css" rel="stylesheet" />
      <link href="font-awesome/css/font-awesome.css" rel="stylesheet" />
      <link href="css/animate.css" rel="stylesheet" />
      <link href="css/style.css" rel="stylesheet" />
      <div className="middle-box text-center animated fadeInDown">
        <h1>404</h1>
        <h3 className="font-bold">Page Not Found</h3>
        <div className="error-desc">
          Sorry, but the page you are looking for has note been found. Try
          checking the URL for error, then hit the refresh button on your
          browser or try found something else in our app.
        </div>
      </div>
      {/* Mainly scripts */}
    </div>
  );
}

export const Lazy = () => {
  return (
  
     
      <div className="middle-box text-center animated fadeInDown" style={{ paddingTop: "200px" }}>
        <div className="sk-spinner sk-spinner-three-bounce">
          <div key={1} className="sk-bounce1" />
          <div key={2} className="sk-bounce2" />
          <div key={3} className="sk-bounce3" />
        </div>
      </div>
   
  );
}



export default Page404;
