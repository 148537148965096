import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import jwt_decode from "jwt-decode";
import Logout from "../component/Logout";
const TimeOut = ({ history }) => {
  const [logout, setLogout] = useState(false)

  useEffect(() => {
    //acts when routes changes
    history.listen(() => {
      let dec
      localStorage.getItem("token") ?
        dec = jwt_decode(localStorage.getItem("token")) : dec = { exp: 0 }
      var seconds = new Date().getTime() / 1000;
      seconds > dec.exp && setLogout(true)
    })
    //acts on refresh
    window.addEventListener("DOMContentLoaded", function () {
      let dec
      localStorage.getItem("token") ?
        dec = jwt_decode(localStorage.getItem("token")) : dec = { exp: 0 }
      var seconds = new Date().getTime() / 1000;
      seconds > dec.exp && setLogout(true)
    })
    // eslint-disable-next-line
  }, [])
  return logout ? <Logout /> : <></>
}

export default withRouter(TimeOut)

 // window.onpopstate = ()=> {
      //   !localStorage.getItem("token")?history.push("/"):void(0)
      // }