const isAuth = () => {
  return localStorage.getItem("token") ? localStorage.getItem("token") : false;
};
export const userType = () => {
  return localStorage.getItem("type") ? localStorage.getItem("type") : "";
};
export default isAuth;



