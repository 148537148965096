import React, { createContext, useState } from "react";
import { BrowserRouter, Switch, Route, Link, Redirect } from "react-router-dom";
import { userType } from "./lib/isAuth";
import PageTop from "./lib/PageTop";
import TimeOut from "./lib/TimeOut";
import 'react-responsive-modal/styles.css';
//free for all---------------------------------------
import user from "./component/assets/no-pic.png";
import NavbarPic from "./lib/NavbarPic";
import jwt_decode from "jwt-decode";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import logo from "./logo.png";
import { Lazy } from "./component/404";

const Landing = React.lazy(() => import("./component/LandingPage"));
const CvMaker = React.lazy(() => import("./component/CvMaker"));
const Error = React.lazy(() => import("./component/404"));
const ErrorHandel = React.lazy(() => import("./component/Errorhandle"));
const Login = React.lazy(() => import("./component/Login"));
const Verify = React.lazy(() => import("./component/Verify"));
const Signup = React.lazy(() => import("./component/Signup"));
const Logout = React.lazy(() => import("./component/Logout"));
const ForgetPassword = React.lazy(() => import("./component/ForgetPassword"));
const ForgetPasswordEmp = React.lazy(() => import("./component/ForgetPasswordEmp"));
const ChangePassword = React.lazy(() => import("./component/ChangePassword"));
const ResetPassword = React.lazy(() => import("./component/ResetPassword"));
const EmpResetPassword = React.lazy(() => import("./component/EmpResetPassword"));
const JobDetailPage = React.lazy(() => import("./component/jobdetails"));
const Downloads = React.lazy(() => import("./component/Downloads"));
//free for all---------------------------------------

//user pages-------------------------------------------
const WelcomeUser = React.lazy(() => import("./component/Users/WelcomeUser"));
const Profile = React.lazy(() => import("./component/Users/Profile"));
const Education = React.lazy(() => import("./component/Users/Education"));
const Experience = React.lazy(() => import("./component/Users/Experience"));
const Applications = React.lazy(() => import("./component/Users/Applications"));
const PrvApplications = React.lazy(() => import("./component/Users/PrvApplications"));
const Resume = React.lazy(() => import("./component/Users/Resume"));
const AvailableJobsUser = React.lazy(() => import("./component/Users/AvailableJobsUser"));
const ApplicationDetail = React.lazy(() => import("./component/Users/ApplicationDetails"));
const JobApply = React.lazy(() => import("./component/Users/jobApply"));
const PreviewProfile = React.lazy(() => import("./component/Users/PreviewProfile"));
//user pages-------------------------------------------

//Employee pages-------------------------------------------
const AllActivejobs = React.lazy(() => import("./component/Employee/AllActivejobs"));
const WelcomeEmployee = React.lazy(() => import("./component/Employee/WelcomeEmployee"));
const ViewRequestDetails = React.lazy(() => import("./component/Employee/ViewRequestDetails"));
const PortalStatus = React.lazy(() => import("./component/Employee/PortalStatus"));
//const Allemployees  = React.lazy(() => import( "./component/Employee/Allemployees"));
const EditLanding = React.lazy(() => import("./component/Employee/EditLanding"));
const QueryBuilder = React.lazy(() => import("./component/Employee/QueryBuilder"));
const Viewjobs = React.lazy(() => import("./component/Employee/viewjobs"));
const JobsApproval = React.lazy(() => import("./component/Employee/JobsApproval"));
const PostedJobsStatus = React.lazy(() => import("./component/Employee/PostedJobsStatus"));
const ApplicantRecoard = React.lazy(() => import("./component/Employee/ApplicantRecord"));
const CreateJobs = React.lazy(() => import("./component/Employee/CreateJobs"));
const AcceptedApplicants = React.lazy(() => import("./component/Employee/AcceptedApplicants"));
const EmployeeProfile = React.lazy(() => import("./component/Employee/EmployeeProfile"));
const Schedule = React.lazy(() => import("./component/Employee/Schedule"));
const MyJobs = React.lazy(() => import("./component/Employee/MyJobs"));
const MyJobsApplications = React.lazy(() => import("./component/Employee/MyjobsAppliations"));
const Panalist = React.lazy(() => import("./component/Employee/panalist"));
const SystemLogs = React.lazy(() => import("./component/Employee/systemLogs"));
const UserEvaluation = React.lazy(() => import("./component/Employee/UserEvaluation")); // user evaluation and schedule combine
const Jobshow = React.lazy(() => import("./component/Employee/jobshow"));
const RoleManagement = React.lazy(() => import("./component/Employee/RoleManagement"));
const EmployeeLogin = React.lazy(() => import("./component/LoginEmployee"));
const UserData = React.lazy(() => import("./component/Employee/UserData"));
const HiringRequests = React.lazy(() => import("./component/Employee/HiringRequests"));
const HiringRequestsStatus = React.lazy(() => import("./component/Employee/HiringRequestsStatus"));
const RequestEmployee = React.lazy(() => import("./component/Employee/RequestEmployee"));
const EmpChangePassword = React.lazy(() => import("./component/Employee/EmpChangePassword"));
const AllRegisteredUsers = React.lazy(() => import("./component/Employee/AllRegisteredUsers"));
const EmployeeRolesGroups = React.lazy(() => import("./component/Employee/EmployeeRoles"));
const RolesGroups = React.lazy(() => import("./component/Employee/RolesGroup"));
const BlockEmployee = React.lazy(() => import("./component/Employee/BlockEmployee"));
const AddEmployee = React.lazy(() => import("./component/Employee/AddEmployee"));
const Letter = React.lazy(() => import("./component/Employee/Letter"));
const ApplicationLogs = React.lazy(() => import("./component/Employee/applicationLogs"));
const PanelistsLogs = React.lazy(() => import("./component/Employee/panelistLogs"));
const RolesLogs = React.lazy(() => import("./component/Employee/rolesLogs"));
const HiringLogs = React.lazy(() => import("./component/Employee/hiringLogs"));

/// utils------------------------------------------------


export const Context = createContext();

const App = (props) => {

  const [popup, setPopup] = useState({ open: false });
  popup.open && void (0)
  userType() && void (0)

  // const [dark, setDark] = useState(false);

  // const ToggleDark = () => {
  //   setDark(!dark)
  //   console.log("dark")
  //   document.documentElement.classList.toggle('dark-mode')
  // }
  return (
    <BrowserRouter>
      <Context.Provider value={setPopup}>
        <Switch>
          {/* Applicant Routes */}
          <Route
            exact
            path={[
              "/userProfile",
              "/availablejobs",
              "/applications",
              "/PrvApplications",
              "/profile",
              "/jobApply",
              "/ApplicationDetail",
              "/Education",
              "/Experience",
              "/resume",
              "/jobdetail",
              "/changePass",
              "/PreviewProfile",
            ]}
          >
            <div id="wrapper">
              <nav
                className="navbar-default navbar-static-side"
                role="navigation"
                style={{ background: "#32404f" }}
              >
                <div className="sidebar-collapse">
                  <ul className="nav metismenu" id="side-menu">
                    <li className="nav-header">
                      <TimeOut />
                      <PageTop />
                      <Link to="/userProfile">
                        <img
                          style={{
                            display: "flex",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "100%",
                          }}
                          src={logo}
                          alt="bahria"
                        />
                      </Link>
                    </li>
                    <li>
                      <a aria-expanded="true" href>
                        <i className="fa fa-user-circle" />{" "}
                        <span className="nav-label">Profile</span>
                        <span className="fa arrow" />
                      </a>
                      <ul className="nav nav-second-level collapse in">
                        <li>
                          <Link style={{ color: "white" }} to="/profile">
                            <div className="slabel">
                              <i className="fa fa-vcard-o"></i>User Info
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link style={{ color: "white" }} to="/Education">
                            <div className="slabel">
                              <i className="fa fa-graduation-cap"></i>Education
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link style={{ color: "white" }} to="/Experience">
                            <div className="slabel">
                              <i className="fa fa-handshake-o"></i>Experience
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link style={{ color: "white" }} to="/resume">
                            <div className="slabel">
                              <i
                                className="fa fa-file-o"
                                style={{ paddingRight: "4px" }}
                              ></i>
                              Resume
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link style={{ color: "white" }} to="/PreviewProfile">
                            <div className="slabel">
                              <i
                                className="fa fa-eye"
                                style={{ paddingRight: "4px" }}
                              ></i>
                              Preview Profile
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a aria-expanded="true" href>
                        <i className="fa fa-clipboard" />{" "}
                        <span className="nav-label">Jobs</span>
                        <span className="fa arrow" />
                      </a>
                      <ul className="nav nav-second-level collapse in">
                        <li>
                          <Link style={{ color: "white" }} to="/availablejobs">
                            <div className="slabel">
                              <i className="fa fa-suitcase"></i>All Jobs
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link style={{ color: "white" }} to="/applications">
                            <div className="slabel">
                              <i className="fa fa-tag"></i>Current Applications
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            style={{ color: "white" }}
                            to="/PrvApplications"
                          >
                            <div className="slabel">
                              <i className="fa fa-tags"></i>Previous
                              Applications
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
              <div id="page-wrapper" className="gray-bg dashbard-1">
                <div className="row border-bottom">
                  <nav
                    className="navbar navbar-static-top"
                    role="navigation"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="navbar-header">
                      <button className="navbar-minimalize minimalize-styl-2 btn btn-primary">
                        <i className="fa fa-bars"></i>
                      </button>
                    </div>
                    <ul className="nav navbar-top-links navbar-right">
                      <li style={{ padding: "20px" }}>
                        <span className="m-r-sm text-muted welcome-message">
                          Welcome to Bahria Career Portal
                        </span>
                      </li>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "9px",
                        }}
                      >
                        <NavbarPic />
                        <div
                          data-toggle="dropdown"
                          className="dropdown-toggle"
                          aria-expanded="false"
                          style={{ marginTop: "9px", cursor: "pointer" }}
                        >
                          <span className="text-muted text-xs block">
                            {localStorage.getItem("name")
                              ? localStorage.getItem("name").toUpperCase()
                              : "USER"}{" "}
                            <b className="caret" />
                          </span>
                        </div>
                        <div
                          className="dropdown-menu animated fadeInRight m-t-xs"
                          x-placement="bottom-start"
                          style={{
                            position: "absolute",
                            top: "51px",
                            right: "10px",
                            willChange: "top, left",
                            height: "72px",
                          }}
                        >
                          <li>
                            <a
                              href="/changePass"
                              style={{
                                color: "grey",
                                top: "51px",
                                right: "10px",
                              }}
                            >
                              <i className="fa fa-unlock-alt"></i> Change
                              Password
                            </a>
                          </li>
                          {/* <li>
                            <a
                              style={{
                                color: "grey",
                                top: "51px",
                                right: "10px",
                              }}
                              onClick={() => ToggleDark()}
                            >
                              <i className={dark ? "fa fa-sun-o" : "fa fa-moon-o"}></i>{" "}{dark ? "Light Mode" : "Dark Mode"}
                            </a>
                          </li> */}
                          <li>
                            <a
                              href="/logout"
                              style={{
                                color: "grey",
                                top: "51px",
                                right: "10px",
                              }}
                            >
                              <i className="fa fa-sign-out"></i> Log out
                            </a>
                          </li>
                        </div>
                      </div>
                    </ul>
                  </nav>
                </div>
                <div className="row border-bottom white-bg dashboard-header">
                  <Route exact path="/userProfile">
                    {userType() === "applicant" ? (
                      <React.Suspense fallback={<Lazy />} ><WelcomeUser /></React.Suspense>

                    ) : (
                      <Redirect to="/wait" />
                    )}
                  </Route>
                  <Route exact path="/jobApply">
                    {userType() === "applicant" ? (
                      <React.Suspense fallback={<Lazy />} ><JobApply /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/PreviewProfile">
                    {userType() === "applicant" ? (
                      <React.Suspense fallback={<Lazy />} ><PreviewProfile /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/availableJobs">
                    {userType() === "applicant" ? (
                      <React.Suspense fallback={<Lazy />} > <AvailableJobsUser /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/applications">
                    {userType() === "applicant" ? (
                      <React.Suspense fallback={<Lazy />} ><Applications /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/jobdetail">
                    <React.Suspense fallback={<Lazy />} ><JobDetailPage /></React.Suspense>
                  </Route>
                  <Route exact path="/PrvApplications">
                    {userType() === "applicant" ? (
                      <React.Suspense fallback={<Lazy />} ><PrvApplications /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/profile">
                    {userType() === "applicant" ? (
                      <React.Suspense fallback={<Lazy />} > <Profile /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/Education">
                    {userType() === "applicant" ? (
                      <React.Suspense fallback={<Lazy />} ><Education /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/Experience">
                    {userType() === "applicant" ? (
                      <React.Suspense fallback={<Lazy />} ><Experience /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/resume">
                    {userType() === "applicant" ? (
                      <React.Suspense fallback={<Lazy />} > <Resume /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/ApplicationDetail">
                    {userType() === "applicant" ? (
                      <React.Suspense fallback={<Lazy />} ><ApplicationDetail /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/changePass">
                    {userType() === "applicant" ? (
                      <React.Suspense fallback={<Lazy />} ><ChangePassword /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                </div>
              </div>
            </div>
          </Route>
          {/*Employee Routes */}
          <Route
            exact
            path={[
              "/PortalStats",
              "/Allemployees",
              "/AllActivejobs",
              "/viewjobs",
              "/jobsapproval",
              "/jobdetail",
              "/ViewRequestDetails/:id",
              "/roles",
              "/rolesGroups",
              "/EmployeeRolesGroups",
              "/AllRegisteredUsers",
              "/jobslogs",
              "/roleslogs",
              "/applicationlogs",
              "/hiringlogs",
              "/panelistslogs",
              "/editLandingpage",
              "/reports",
              "/WelcomePage",
              "/addJob",
              "/myJobs",
              "/PostedJobsStatus",
              "/jobdetails",
              "/myJobsApplications/:jobId",
              "/reviewApplications",
              "/interviewSchedule",
              "/profileinfo",
              "/ApplicantRecord/:userid",
              "/jobshow",
              "/Evaluation",
              "/panalists",
              "/UserData",
              "/hiringRequests",
              "/RequestEmployee",
              "/HiringRequestsStatus",
              "/EmpChangePass",
              "/BlockEmployee",
              "/AddEmployee",
              "/Letter"
            ]}
          >
            <div id="wrapper">
              <nav
                className="navbar-default navbar-static-side"
                role="navigation"
                style={{ background: "#32404f" }}
              >
                <div className="sidebar-collapse">
                  <ul className="nav metismenu" id="side-menu">
                    <li className="nav-header">
                      <TimeOut />
                      <PageTop />
                      <Link to="/WelcomePage">
                        <img
                          style={{
                            display: "flex",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "100%",
                          }}
                          src={logo}
                          alt="bahria"
                        />
                      </Link>
                    </li>
                    {/* {userType() === "recruiter" ? (
                      <li>
                        <Link style={{ color: "white" }} to="/profileinfo">
                          <i className="fa fa-user-circle"></i>
                          <span className="nav-label">Profile</span>
                        </Link>
                      </li>
                    ) : (s
                      <></>
                    )} */}
                    {/* Admin========================================================================================================================== */}
                    <>
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          1
                        ) ? (
                        <li>
                          <Link style={{ color: "white" }} to="/PortalStats">
                            <i className="fa fa-area-chart"></i>
                            <span className="nav-label">Portal Status</span>
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          2
                        ) ? (
                        <li>
                          <Link style={{ color: "white" }} to="/AllActivejobs">
                            <i className="fa fa-graduation-cap"></i>
                            <span className="nav-label">All Posted Jobs</span>
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          3
                        ) ? (
                        <li>
                          <Link style={{ color: "white" }} to="/jobsapproval">
                            <i className="fa fa-check-circle"></i>
                            <span className="nav-label">Jobs Approval</span>
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}

                      {userType() === "recruiter" && (jwt_decode(localStorage.getItem("token")).roles.includes(
                        20
                      ) || jwt_decode(localStorage.getItem("token")).roles.includes(
                        4
                      ))
                        ? (
                          <>
                            <li>
                              <a style={{ color: "white" }} href>
                                <i className="fa fa-briefcase"></i>
                                <span className="nav-label">
                                  Employees
                                </span>{" "}
                                <span className="fa arrow"></span>
                              </a>
                              <ul className="nav nav-second-level">
                                {userType() === "recruiter" &&
                                  jwt_decode(localStorage.getItem("token")).roles.includes(
                                    4
                                  ) ? (
                                  <>
                                    <li>
                                      <Link style={{ color: "white" }} to="/Allemployees">
                                        <i className="fa fa-vcard-o"></i>
                                        <span className="nav-label">All Employees</span>
                                      </Link>
                                    </li>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {userType() === "recruiter" &&
                                  jwt_decode(localStorage.getItem("token")).roles.includes(
                                    20
                                  ) ? (
                                  <>
                                    <li>
                                      <Link
                                        style={{ color: "white" }}
                                        to="/AddEmployee"
                                      >
                                        <i className="fa fa-id-badge"></i>
                                        <span className="nav-label">
                                          Add Employee
                                        </span>
                                      </Link>
                                    </li>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </ul>
                            </li>
                          </>
                        ) : (
                          <></>
                        )}

                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          5
                        ) ? (
                        <>
                          <li>
                            <Link style={{ color: "white" }} to="/rolesGroups">
                              <i className="fa fa-eye"></i>
                              <span className="nav-label">Roles Management</span>
                            </Link>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          6
                        ) ? (
                        <>
                          <li>
                            <a style={{ color: "white" }} href >
                              <i className="fa fa-file-archive-o"></i>
                              <span className="nav-label">System Logs</span>
                              <span className="fa arrow"></span>
                            </a>
                            <ul className="nav nav-second-level">
                              <li>
                                <Link to="/jobslogs">Jobs Logs</Link>
                              </li>
                              <li>
                                <Link to="/applicationlogs">Application Logs</Link>
                              </li>
                              <li>
                                <Link to="/panelistslogs">Panelist Logs</Link>
                              </li>
                              <li>
                                <Link to="/hiringlogs">Hiring Request Logs</Link>
                              </li>
                              <li>
                                <Link to="/roleslogs">Roles Logs</Link>
                              </li>
                            </ul>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          7
                        ) ? (
                        <li>
                          <Link
                            style={{ color: "white" }}
                            to="/editLandingpage"
                          >
                            <i className="fa fa-th-large"></i>
                            <span className="nav-label">
                              Update landing page
                            </span>
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          9
                        ) ? (
                        <>
                          <li>
                            <Link
                              style={{ color: "white" }}
                              to="/AllRegisteredUsers"
                            >
                              <i className="fa fa-group"></i>
                              <span className="nav-label">
                                All Registered Users
                              </span>
                            </Link>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* recreuiterr========================================================================================================================== */}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          10
                        ) ? (
                        <li>
                          <Link style={{ color: "white" }} to="/addJob">
                            <i className="fa fa-bullhorn"></i>
                            <span className="nav-label">Post Job</span>
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          11
                        ) ? (
                        <li>
                          <a style={{ color: "white" }} href>
                            <i className="fa fa-briefcase"></i>
                            <span className="nav-label">
                              My Posted Jobs
                            </span>{" "}
                            <span className="fa arrow"></span>
                          </a>
                          <ul className="nav nav-second-level">
                            <li>
                              <Link to="/myJobs">Approved Jobs</Link>
                            </li>
                            <li>
                              <Link to="/PostedJobsStatus">Pending Jobs</Link>
                            </li>
                          </ul>
                        </li>
                      ) : (
                        <></>
                      )}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          13
                        ) ? (
                        <li>
                          <Link
                            style={{ color: "white" }}
                            to="/interviewSchedule"
                          >
                            <i className="fa fa-clock-o"></i>
                            <span className="nav-label">
                              Interview Schedules
                            </span>
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          12
                        ) ? (
                        <li>
                          <Link
                            style={{ color: "white" }}
                            to="/reviewApplications"
                          >
                            <i className="fa fa-tasks"></i>
                            <span className="nav-label">Review Applicants</span>
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {/* department========================================================================================================================== */}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          15
                        ) ? (
                        <>
                          <li>
                            <Link style={{ color: "white" }} to="/panalists">
                              <i className="fa fa-male"></i>
                              <span className="nav-label">Create Panelist</span>
                            </Link>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          14
                        ) ? (
                        <>
                          <li>
                            <Link style={{ color: "white" }} to="/Evaluation">
                              <i className="fa fa-group"></i>
                              <span className="nav-label">
                                Applicant Evaluation
                              </span>
                            </Link>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          16
                        ) ? (
                        <>
                          <li>
                            <a style={{ color: "white" }} href>
                              <i className="fa fa-briefcase"></i>
                              <span className="nav-label">
                                Create Hiring Request
                              </span>{" "}
                              <span className="fa arrow"></span>
                            </a>
                            <ul className="nav nav-second-level">
                              <li>
                                <Link
                                  style={{ color: "white" }}
                                  to="/RequestEmployee"
                                >
                                  <i className="fa fa-plus-circle"></i>
                                  <span className="nav-label">
                                    Request New Resource
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  style={{ color: "white" }}
                                  to="/HiringRequestsStatus"
                                >
                                  <i className="fa fa-flag"></i>
                                  <span className="nav-label">
                                    Requests Status
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          17
                        ) ? (
                        <>
                          <li>
                            <Link
                              style={{ color: "white" }}
                              to="/HiringRequests"
                            >
                              <i className="fa fa-male"></i>
                              <span className="nav-label">Hiring Requests Approval</span>
                            </Link>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          8
                        ) ? (
                        <>
                          <li>
                            <Link style={{ color: "white" }} to="/reports">
                              <i className="fa fa-print"></i>
                              <span className="nav-label">Reports</span>
                            </Link>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      {userType() === "recruiter" &&
                        jwt_decode(localStorage.getItem("token")).roles.includes(
                          18
                        ) ? (
                        <>
                          <li>
                            <Link style={{ color: "white" }} to="/Letter">
                              <i className="fa fa-print"></i>
                              <span className="nav-label">Print Letter</span>
                            </Link>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  </ul>
                </div>
              </nav>

              <div id="page-wrapper" className="gray-bg dashbard-1">
                <div className="row border-bottom">
                  <nav
                    className="navbar navbar-static-top"
                    role="navigation"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="navbar-header">
                      <button className="navbar-minimalize minimalize-styl-2 btn btn-primary">
                        <i className="fa fa-bars"></i>
                      </button>
                    </div>
                    <ul className="nav navbar-top-links navbar-right">
                      <li style={{ padding: "20px" }}>
                        <span className="m-r-sm text-muted welcome-message">
                          Welcome to Bahria Career Portal
                        </span>
                      </li>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "9px",
                        }}
                      >
                        <img
                          style={{
                            width: "35px",
                            height: "35px",
                            marginRight: "10px",
                          }}
                          alt="you"
                          className="rounded-circle"
                          src={user}
                        />
                        <div
                          data-toggle="dropdown"
                          className="dropdown-toggle"
                          href="#"
                          aria-expanded="false"
                          style={{ marginTop: "9px", cursor: "pointer" }}
                        >
                          <span className="text-muted text-xs block">
                            {localStorage.getItem("token")!==null && jwt_decode(localStorage.getItem("token")).name
                              ? jwt_decode(localStorage.getItem("token")).name.toUpperCase()
                              : "Employee"}{" "}
                            <b className="caret" />
                          </span>
                        </div>
                        <div
                          className="dropdown-menu animated fadeInRight m-t-xs"
                          x-placement="bottom-start"
                          style={{
                            position: "absolute",
                            top: "51px",
                            right: "10px",
                            willChange: "top, left",
                            height: "110px",
                          }}
                        >

                          <li>
                            <a
                              href="/WelcomePage"
                              style={{
                                color: "grey",
                                top: "51px",
                                right: "10px",
                              }}
                            >
                              <i className="fa fa-user"></i> Profile
                            </a>
                          </li>
                          <li>
                            <a
                              href="/EmpChangePass"
                              style={{
                                color: "grey",
                                top: "51px",
                                right: "10px",
                              }}
                            >
                              <i className="fa fa-unlock-alt"></i> Change
                              Password
                            </a>
                          </li>
                          <li>
                            <a
                              href="/logout"
                              style={{
                                color: "grey",
                                top: "51px",
                                right: "10px",
                              }}
                            >
                              <i className="fa fa-sign-out"></i> Log out
                            </a>
                          </li>
                        </div>
                      </div>
                    </ul>
                  </nav>
                </div>
                <div className="row border-bottom white-bg dashboard-header">
                  {/*Admin access  ===================================================================================================================== */}
                  <Route exact path="/PortalStats">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        1
                      ) ? (
                      <React.Suspense fallback={<Lazy />} > <PortalStatus /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/AllActivejobs">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        2
                      ) ? (
                      <React.Suspense fallback={<Lazy />} > <AllActivejobs /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/viewjobs">
                    {userType() === "recruiter" ? (
                      <React.Suspense fallback={<Lazy />} > <Viewjobs /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/jobsapproval">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        3
                      ) ? (
                      <React.Suspense fallback={<Lazy />} > <JobsApproval /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/Allemployees">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        4
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >  <BlockEmployee /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/jobdetail">
                    {userType() === "recruiter" ? (
                      <React.Suspense fallback={<Lazy />} > <JobDetailPage /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/ViewRequestDetails/:id">
                    {userType() === "recruiter" ? (
                      <React.Suspense fallback={<Lazy />} > <ViewRequestDetails /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  {/*Recruiterr Access ===================================================================================================================== */}
                  <Route exact path="/WelcomePage">
                    {userType() === "recruiter" ? (
                      <React.Suspense fallback={<Lazy />} > <WelcomeEmployee /></React.Suspense>
                    ) : (
                      <Redirect to="/wait" />
                    )}
                  </Route>
                  <Route exact path="/myJobs">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        11
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >  <MyJobs /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/PostedJobsStatus">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        11
                      ) ? (
                      <React.Suspense fallback={<Lazy />} > <PostedJobsStatus /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/jobdetails">
                    {userType() === "recruiter" ? (
                      <React.Suspense fallback={<Lazy />} > <JobDetailPage /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/myJobsApplications/:jobId">
                    {userType() === "recruiter" ? (
                      <React.Suspense fallback={<Lazy />} > <MyJobsApplications /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/addJob">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        10
                      ) ? (
                      <React.Suspense fallback={<Lazy />} > <CreateJobs /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/reviewApplications">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        12
                      ) ? (
                      <React.Suspense fallback={<Lazy />} > <AcceptedApplicants /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/ApplicantRecord/:userid">
                    {userType() === "recruiter" ? (
                      <React.Suspense fallback={<Lazy />} > <ApplicantRecoard /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/interviewSchedule">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        13
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >  <Schedule /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/profileinfo">
                    {userType() === "recruiter" ? (
                      <React.Suspense fallback={<Lazy />} >   <EmployeeProfile /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  {/*All Employee Access ===================================================================================================================== */}
                  <Route exact path="/jobshow">
                    {userType() === "recruiter" ? (
                      <React.Suspense fallback={<Lazy />} >   <Jobshow /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/Evaluation">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        14
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >  <UserEvaluation /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/panalists">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        15
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >  <Panalist /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/roles">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        5
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >   <RoleManagement /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/rolesGroups">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        5
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >  <RolesGroups /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/EmployeeRolesGroups">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        5
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >  <EmployeeRolesGroups /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/jobslogs">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        6
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >  <SystemLogs /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/roleslogs">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        6
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >  <RolesLogs /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/applicationlogs">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        6
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >  <ApplicationLogs /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/hiringlogs">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        6
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >  <HiringLogs /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/panelistslogs">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        6
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >  <PanelistsLogs /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/editLandingpage">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        7
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >   <EditLanding /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/reports">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        8
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >   <QueryBuilder /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/AllRegisteredUsers">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        9
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >   <AllRegisteredUsers /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/UserData">
                    {userType() === "recruiter" ? (
                      <React.Suspense fallback={<Lazy />} > <UserData /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/HiringRequests">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        17
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >   <HiringRequests /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/RequestEmployee">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        16
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >   <RequestEmployee /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/HiringRequestsStatus">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        16
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >  <HiringRequestsStatus /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/EmpChangePass">
                    {userType() === "recruiter" ? (
                      <React.Suspense fallback={<Lazy />} >  <EmpChangePassword /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/AddEmployee">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        20
                      ) ? (
                      <React.Suspense fallback={<Lazy />} > <AddEmployee /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route exact path="/Letter">
                    {userType() === "recruiter" &&
                      jwt_decode(localStorage.getItem("token")).roles.includes(
                        18
                      ) ? (
                      <React.Suspense fallback={<Lazy />} >   <Letter /></React.Suspense>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  {/* <Route exact path="/BlockEmployee">
                    {userType() === "recruiter" && jwt_decode(localStorage.getItem("token")).roles.includes(
                      19
                    ) ? (
                      <BlockEmployee />
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route> */}
                </div>
              </div>
            </div>
          </Route>
          {/*Open Access Routes*/}
          {/* setting root path to /careers */}
          <Route exact path="/">
            {userType() === "applicant" ? (
              <Redirect to="/userProfile" />
            ) : userType() === "recruiter" ? (
              <Redirect to="/WelcomePage" />
            ) : (
              <React.Suspense fallback={<Lazy />} > <Landing /></React.Suspense>
            )}
          </Route>
          <Route exact path="/login">
            <React.Suspense fallback={<Lazy />} > <Login /></React.Suspense>
          </Route>
          <Route exact path="/Employeelogin">
            <React.Suspense fallback={<Lazy />} > <EmployeeLogin /></React.Suspense>
          </Route>
          <Route exact path="/signup">
            <React.Suspense fallback={<Lazy />} > <Signup /></React.Suspense>
          </Route>
          <Route exact path="/verify/:id">
            <React.Suspense fallback={<Lazy />} > <Verify /></React.Suspense>
          </Route>
          <Route exact path="/logout">
            <React.Suspense fallback={<Lazy />} > <Logout /></React.Suspense>
          </Route>
          <Route exact path="/yeet">
            <React.Suspense fallback={<Lazy />} >   <ErrorHandel /> </React.Suspense>
          </Route>
          <Route exact path="/Forget">
            <React.Suspense fallback={<Lazy />} > <ForgetPassword /></React.Suspense>
          </Route>
          <Route exact path="/ForgetEmp">
            <React.Suspense fallback={<Lazy />} > <ForgetPasswordEmp /></React.Suspense>
          </Route>
          <Route exact path="/reset/:id">
            <React.Suspense fallback={<Lazy />} > <ResetPassword /></React.Suspense>
          </Route>
          <Route exact path="/EmpReset/:id">
            <React.Suspense fallback={<Lazy />} > <EmpResetPassword /></React.Suspense>
          </Route>
          <Route exact path="/holdup">
            <React.Suspense fallback={<Lazy />} > <Error /> </React.Suspense>
          </Route>
          <Route exact path="/wait">
            <Lazy />
          </Route>
          <Route exact path="/CvBuilder">
            <React.Suspense fallback={<Lazy />} > <CvMaker /></React.Suspense>
          </Route>
          <Route exact path="/Downloads">
            <React.Suspense fallback={<Lazy />} > <Downloads /></React.Suspense>
          </Route>
          <Route>
            <React.Suspense fallback={<Lazy />} > <Error /> </React.Suspense>
          </Route>
        </Switch>
      </Context.Provider>
    </BrowserRouter>
  );
};

export default App;
