import server from "./ServerIP"

const apiList = {
  login: `${server}/api/employees/login`,
  employeeData: `${server}/api/employees/getdetail/`,
  postJob: `${server}/api/jobs/postjob`,
  UpdateJobStatus: `${server}/api/jobs/update`,
  GetAllJobs: `${server}/api/jobs/getalljobs/`,
  GetAllPostedJobs: `${server}/api/jobs/alljobs`,
  GetUnPublishedJobs: `${server}/api/jobs/alljobs/false`,
  GetAllRoles: `${server}/api/roles/allroles`,
  CountApplicants: `${server}/api/jobapplications/countapplications/`,
  GetJobApplicants: `${server}/api/jobapplications/getapplications/`,
  downloadResume: `${server}/api/users/downloadfile/`,
  UpdateApplicationStatus: `${server}/api/jobapplications/updateapplication`,
  ScheduledInterviews: `${server}/api/jobapplications/getallapplications`,
  downloadProfileImage: `${server}/api/users/downloadimage/`,
  downloadCoverImage: `${server}/api/landing/downloadimage/`,
  getUserRecord: `${server}/api/jobapplications/getuserapplications/`,
  applicationsToReview: `${server}/api/jobapplications/getallapplications/`,
  evaluateApplication: `${server}/api/jobapplications/addevaluation`,
  CreateGroup: `${server}/api/roles/addrolesgroup`,
  GetGroup: `${server}/api/roles/allrolesgroup`,
  DeleteGroup: `${server}/api/roles/deleterolegroup`,
  GetAllRolesInGroup: `${server}/api/roles/getrolesassigntogroup/`,
  EditGroupRoles: `${server}/api/roles/updateroleassigntogroup`,
  AssignRolesToGroup: `${server}/api/roles/addroleassigntogroup`,
  PostRequest: `${server}/api/hiringrequest/add`,
  GetPostedRequest: `${server}/api/hiringrequest/getall`,
  GetPostedRequestData: `${server}/api/hiringrequest/get`,
  UpdateRequestStatus: `${server}/api/hiringrequest/update`,
  UpdateLandingData: `${server}/api/landing/update`,
  AddLandingData: `${server}/api/landing/add`,
  GetLandingData: `${server}/api/landing/all`,
  UploadLandingPic: `${server}/api/landing/uploadimage`,
  GetEmployeeByDept: `${server}/api/employees/getemployee/`,
  ChangePasswordEmp: `${server}/api/employees/changepassword/`,
  ResetPasswordEmp: `${server}/api/employees/reset_password/`,
  ForgetPasswordEmp: `${server}/api/employees/forgetpassword`,
  getAllRegisteredUsers: `${server}/api/users/allusers`,
  getAllRegisteredEmployee: `${server}/api/employees/allemployees`,
  getEmployeeRoleGroup: `${server}/api/roles/getrolesgroupassigntoemployees/`,
  AssignRoleGroupToEmployee: `${server}/api/roles/addrolesgroupassigntoemployee/`,
  GenerateReport: `${server}/api/employees/reportCustom/pdf`,
  CountAllJobs: `${server}/api/jobs/countall`,
  GetPanalist: `${server}/api/employees/getpanelistdetail`,
  CreatePanalist: `${server}/api/employees/addpanelist`,
  UpdatePanelist: `${server}/api/employees/updatepanelist`,
  PortalStatus: `${server}/api/jobs/countweekjobs`,
  ApplicationStatus: `${server}/api/jobapplications/countweekapplications`,
  CountAllApplications: `${server}/api/jobapplications/countall`,
  CountAllUsers: `${server}/api/users/countall`,
  CountMyJobsApplications: `${server}/api/jobapplications/countweekapplications/`,
  CountEmployeeJobs: `${server}/api/jobs/countjobs/`,
  UpdateEmployeeStatus: `${server}/api/employees/update`,
  GetEmpMail: `${server}/api/employees/getemail/`,
  GetDepartment: `${server}/api/departments/alldepartments`,
  AddEmployee: `http://172.16.19.120:9091/addemployee/`,
  GetJobsLogs: `${server}/api/systemlogs/getjobslogs`,
  GetRolesLogs: `${server}/api/systemlogs/getroleslogs`,
  GetApplicationLogs: `${server}/api/systemlogs/getapplicationslogs`,
  GetHiringRequestLogs: `${server}/api/systemlogs/gethiringrequestlogs`,
  GetPanelistLogs: `${server}/api/systemlogs/getpanelistlogs`
  
};


export default apiList;
