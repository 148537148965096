import { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Context } from "../App";
const Logout = (props) => {
  const setPopup = useContext(Context);
  let user = localStorage.getItem("type");
  useEffect(() => {
    localStorage.clear()
    setPopup({ open: true });
    // eslint-disable-next-line
  }, []);

  let goto = user === "applicant" ? "/login" :user === "recruiter" ? "/EmployeeLogin":"/"
  return <Redirect to={goto} />;
};

export default Logout;
