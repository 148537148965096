import { useEffect, useRef, useState } from "react";
import dummy from "../component/assets/no-pic.png";
import apiList from "./apiList";
import axios from "axios";

//UserProfile picture on navbar/top bar when user is logged in
const NavBarPic = () => {
    const [image, setImage] = useState(dummy);
    const unmounted = useRef();
    useEffect(() => {
        axios({
            url: `${apiList.downloadProfileImage + localStorage.getItem("pic")}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            method: "GET",
            responseType: "blob",
        })
            .then((response) => {
                if (!unmounted.current) {
                    var blob = new Blob([response.data]);
                    setImage(URL.createObjectURL(blob));
                }
            })
            .catch((err) => {
                console.log(err); err.response && console.log(err.response.data.message);
            });
        return () => {
            unmounted.current = true;
        };
    });
    return (
        <><img
            src={image}
            title="uWu"
            style={{
                width: "35px",
                height: "35px",
                marginRight: "10px",
            }}
            alt="you"
            className="rounded-circle"
            decoding="async"
        /></>
    );
};
export default NavBarPic;
