import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

const PageTop = ({ history }) => {
  useEffect(() => history.listen(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    // eslint-disable-next-line
  }), [])
  return <></>
}

export default withRouter(PageTop)